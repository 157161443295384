import { useDispatch } from 'react-redux'
import AppReducer from './reducers/AppReducer'
import thunkMiddleware, { ThunkAction, ThunkDispatch } from 'redux-thunk'
import {
	Action,
	AnyAction,
	applyMiddleware,
	combineReducers,
	createStore,
} from 'redux'

const RootReducer = combineReducers({
	appState: AppReducer,
})

export type RootState = ReturnType<typeof RootReducer>
export type GetState = () => RootState
export type ThunkyDispatch = ThunkDispatch<RootState, unknown, AnyAction>
export type ThunkyAction<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>
export const useThunkyDispatch = () => useDispatch<ThunkyDispatch>()

export default (initialState: RootState) => {
	if (typeof window === 'undefined') {
		return createStore(
			RootReducer,
			initialState,
			applyMiddleware(thunkMiddleware)
		)
	}
	const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__'
	if (!window[__NEXT_REDUX_STORE__]) {
		window[__NEXT_REDUX_STORE__] = createStore(
			RootReducer,
			initialState,
			applyMiddleware(thunkMiddleware)
		)
	}
	return window[__NEXT_REDUX_STORE__]
}
