import Api from './Api'
import qs from 'qs'

export default class AppApi extends Api {
	sendOtp = async (params: { mobile: string }) => {
		const url = this.getUrl('send-otp')
		return await this.getClient().post(url, qs.stringify(params))
	}

	login = async (params: {
		mobile: string
		otp: string
		node_token: boolean
	}) => {
		const url = this.getUrl('login')
		return await this.getClient().post(url, qs.stringify(params))
	}
}
