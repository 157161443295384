import { RootState } from '../redux/store'
import { SchemaOf, ValidationError } from 'yup'
import { ValidateOptions } from 'yup/lib/types'

export const formatCurrency = (
	price: number,
	symbol = true,
	roundTo = 2
): string => {
	let priceString = ''
	if (price < 0) {
		priceString += '-'
		price = Math.abs(price)
	}
	if (symbol) {
		priceString += '₹'
	}
	if (roundTo != -1) {
		price = round(price, roundTo)
	}
	priceString += price.toLocaleString('en-IN')
	return priceString
}

const mapStateToApiProps = (rootState: RootState) => {
	const warehouse = rootState.appState.warehouse
	const selectedWarehouse = rootState.appState.activeWarehouseIndex
	// console.log('check node token', rootState.appState.node_token)

	return {
		token: rootState.appState.token,
		node_token: rootState.appState.node_token,
		userId: rootState.appState?.user?.id,
		//TODO change to warehouse[0] NOT TO BE PUSHED
		warehouseId:
			warehouse &&
			selectedWarehouse < warehouse.length &&
			warehouse[selectedWarehouse]
				? warehouse[selectedWarehouse].id
				: null,
	}
}

const round = (value: number, precision: number): number => {
	//TODO: Fix round off issues in javascript
	let rounded = value
	const multiplicator = Math.pow(10, precision)
	rounded = parseFloat((rounded * multiplicator).toFixed(11))
	rounded = Math.round(rounded) / multiplicator
	return +rounded.toFixed(precision)
}

const mapValidationError = (error: ValidationError) => {
	if (error.inner.length == 0) {
		if (error.path) {
			return { [error.path]: error.errors }
		}
		return error.errors
	}
	let errorObject: Record<string, unknown> = {}
	error.inner.forEach((validationError) => {
		errorObject = {
			...errorObject,
			...mapValidationError(validationError),
		}
	})
	return errorObject
}

const validate = (
	schema: SchemaOf<unknown>,
	value: unknown,
	options?: ValidateOptions
) => {
	try {
		const validateOptions = { abortEarly: false, ...options }
		schema.validateSync(value, validateOptions)
	} catch (err) {
		if (err.name === 'ValidationError') {
			return mapValidationError(err)
		}
		throw err
	}
	return {}
}

const unique = (array: string[]) => {
	const seen: Record<string, unknown> = {}
	return array.filter((item) => {
		return seen[item] ? false : (seen[item] = true)
	})
}

const isPresent = (haystack: string[], needles: string[]) => {
	for (const needle of needles) {
		if (haystack.includes(needle)) {
			return true
		}
	}
	return false
}

export default {
	formatCurrency,
	mapStateToApiProps,
	round,
	validate,
	unique,
	isPresent,
}
