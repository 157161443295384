import { AppActionTypes } from '../ActionTypes'
import AppApi from '../../apis/AppApi'
import Toolkit from '../../utils/Toolkit'
import { ModalName } from '../../components/ModalManager'
import Storage, { StorageKey } from '../../utils/Storage'
import { ThunkyAction } from '../store'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialize = (): ThunkyAction<Promise<any>> => {
	return async (dispatch) => {
		const token = Storage.get(StorageKey.TOKEN, null)
		const user = Storage.get(StorageKey.USER, null)
		const warehouse = Storage.get(StorageKey.WAREHOUSE, null)
		const activeWarehouseIndex = Storage.get(StorageKey.ACTIVE_WAREHOSUE, 0)
		const deviceType = Storage.get(StorageKey.DEVICE_TYPE, null)
		const node_token = Storage.get(StorageKey.node_token, null)

		const payload = {
			token,
			user,
			warehouse,
			activeWarehouseIndex,
			deviceType,
			node_token,
		}
		dispatch({ type: AppActionTypes.INITIALIZE, payload })
	}
}

const displayMessage = (
	text: string,
	isError: boolean,
	milliseconds = 3000
) => {
	return {
		type: AppActionTypes.DISPLAY_MESSAGE,
		payload: { text, isError, milliseconds },
	}
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const switchModal = (name: ModalName, data: any = {}) => {
	return { type: AppActionTypes.MODAL_SWITCH, payload: { name, data } }
}

const sendOtp = ({ mobile }): ThunkyAction => {
	mobile = '+91' + mobile
	return async (dispatch, getState) => {
		const apiProps = Toolkit.mapStateToApiProps(getState())
		await new AppApi(apiProps)
			.sendOtp({ mobile })
			.then(() => {
				dispatch({
					type: AppActionTypes.DISPLAY_MESSAGE,
					payload: { text: 'OTP Sent successfully.', isError: false },
				})
			})
			.catch((error) => {
				const data = error?.response?.data
				let text = 'Unable to send OTP. Please enter correct mobile.'

				if (data && data?.message) {
					text = data.message
				}

				dispatch({
					type: AppActionTypes.DISPLAY_MESSAGE,
					payload: { text, isError: true },
				})
			})
	}
}

const login = ({ mobile, otp }): ThunkyAction => {
	mobile = '+91' + mobile
	return async (dispatch, getState) => {
		const apiProps = Toolkit.mapStateToApiProps(getState())
		try {
			const response = await new AppApi(apiProps).login({
				mobile,
				otp,
				node_token: true,
			})

			const result = response.data
			Storage.set(StorageKey.TOKEN, result.token)
			Storage.set(StorageKey.USER, result.data)
			Storage.set(StorageKey.WAREHOUSE, result.data.warehouse)
			Storage.set(StorageKey.ACTIVE_WAREHOSUE, 0)
			Storage.set(StorageKey.node_token, result.node_token)
			dispatch({ type: AppActionTypes.LOGIN_SUCCESS, payload: result })
		} catch (error) {
			const { data } = error.response
			let text = 'Unable to login. Please enter correct credentials.'

			if (data && data.message) {
				text = data.message
			}

			dispatch({
				type: AppActionTypes.DISPLAY_MESSAGE,
				payload: { text, isError: true },
			})
		}
	}
}

const logout = (): ThunkyAction => {
	return async (dispatch) => {
		dispatch({ type: AppActionTypes.LOGOUT_SUCCESS, payload: '' })
		Storage.clear()
		location.reload()
	}
}

const switchStore = (storeid: number): ThunkyAction => {
	const payload = { index: storeid }
	return async (dispatch) => {
		Storage.set(StorageKey.ACTIVE_WAREHOSUE, storeid)
		dispatch({ type: AppActionTypes.WAREHOUSE_SWITCH, payload: payload })
		//location.reload();
	}
}

const setDeviceType = (deviceType: string): ThunkyAction => {
	const payload = { deviceType: deviceType }
	return async (dispatch) => {
		Storage.set(StorageKey.DEVICE_TYPE, deviceType)
		dispatch({ type: AppActionTypes.SET_DEVICE_TYPE, payload: payload })
		//location.reload();
	}
}

const AppAction = {
	initialize,
	displayMessage,
	switchModal,
	sendOtp,
	login,
	logout,
	switchStore,
	setDeviceType,
}

export default AppAction
