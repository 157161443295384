//TODO: Add proper typings for actions

export enum AppActionTypes {
	INITIALIZE = 'app_initialize',
	WAREHOUSE_SWITCH = 'app_warehouse_switch',
	MODAL_SWITCH = 'app_modal_switch',
	LOGIN_SUCCESS = 'app_login_success',
	LOGOUT_SUCCESS = 'app_logout_success',
	DISPLAY_MESSAGE = 'app_display_message',
	LOAD_TRANSFERLIST = 'load_transfer_list',
	START_MIGRATION = 'start_migration',
	MIGRATE_ITEM_SUCCESS = 'migrate_item_success',
	LOAD_AUDIT_LIST = 'load_audit_list',
	ADD_AUDIT_ITEM = 'add_audit_item',
	REMOVE_AUDIT_ITEM = 'remove_audit_item',
	LOAD_EXTRA_ITEM = 'load_extra_item',
	ADD_DISTINCT_AUDIT_ITEM_PRODUCTS = 'add_distinct_audit_item_products',
	SET_DEVICE_TYPE = 'set_device_type',
}
