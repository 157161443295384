export enum StorageKey {
	TOKEN = 'token',
	USER = 'user',
	WAREHOUSE = 'warehouse',
	ACTIVE_WAREHOSUE = 'active_warehouse',
	DEVICE_TYPE = 'device_type',
	node_token = 'node_token',
}

const get = (
	key: StorageKey,
	defaultValue: unknown
	// _throughSession: false
) => {
	const valueJsonString = localStorage.getItem(key)

	try {
		return valueJsonString ? JSON.parse(valueJsonString) : defaultValue
	} catch (error) {
		console.log(error)
	}
	return 'undefined'
}

const set = (key: StorageKey, value: unknown) => {
	localStorage.setItem(key, JSON.stringify(value))
}

const remove = (key: StorageKey) => {
	localStorage.removeItem(key)
}

const clear = () => {
	localStorage.clear()
}

export default { get, set, remove, clear }
