import React, { useEffect } from 'react'
import { AppProps } from 'next/app'
import { Provider } from 'react-redux'
import AppActions from '../redux/actions/AppAction'
import createStore from '../redux/store'
import '../css/app.css'
import NiyoPage from '../utils/NiyoPage'

const App = ({ Component, pageProps }: AppProps) => {
	const store = createStore(undefined)
	useEffect(() => {
		store.dispatch(AppActions.initialize())
	}, [])
	return (
		<Provider store={store}>
			<NiyoPage Component={Component} {...pageProps} />
		</Provider>
	)
}

export default App
