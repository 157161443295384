import { RootState } from '../redux/store'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import Router from 'next/router'

const mapRootState = (rootState: RootState) => {
	return {
		token: rootState.appState.token,
		node_token: rootState.appState.node_token,
		isInitialized: rootState.appState.isInitialized,
		deviceType: rootState.appState.deviceType,
	}
}

const NiyoPage = ({ Component, ...pageProps }) => {
	const { token, node_token, isInitialized, deviceType } =
		useSelector(mapRootState)
	const isPrivate = Component.isPrivate
	const blockAccess =
		(isPrivate === undefined || isPrivate === true) && (!token || !node_token)
	useEffect(() => {
		if (isInitialized && blockAccess) {
			Router.push('/login')
		} else {
			if (!deviceType && token) {
				Router.push('/select-device-type')
			}
		}
	}, [blockAccess, isInitialized])

	if (!isInitialized || blockAccess) {
		return null
	}

	return <Component {...pageProps} />
}

export default NiyoPage
