import User from '../../types/User'
import { AppActionTypes } from '../ActionTypes'
import { ModalName } from '../../components/ModalManager'
import Warehouse from '../../types/Warehouse'
import { AuditItem } from '../../types/AuditItem'
import Product from '../../types/Product'
import { TransferItem } from '../../types/TransferItem'

export interface AppState {
	name: string
	token: string
	user: User
	warehouse: Warehouse[]
	activeWarehouseIndex: number
	modal?: {
		name: ModalName
		data: {
			[key: string]: unknown
		}
	}
	message?: { text: string; isError: boolean; milliseconds: number }
	isInitialized: boolean
	transferList: TransferItem[]
	currentAuditItem: AuditItem
	extraItem: Product
	distictAduitProducts: AuditItem[]
	deviceType: null
	node_token: string
}

const initialState = {
	name: 'WH PWA',
	token: null,
	user: null,
	warehouse: null,
	activeWarehouseIndex: 0,
	modal: null,
	isInitialized: false,
	transferList: null,
	auditlist: null,
	currentAuditItem: null,
	extraItem: null,
	distictAduitProducts: null,
	deviceType: null,
	node_token: null,
}

const initialize = (state: AppState, action) => {
	const payload = action.payload
	return {
		...state,
		isInitialized: true,
		token: payload.token,
		user: payload.user,
		warehouse: payload.warehouse,
		activeWarehouseIndex: payload.activeWarehouseIndex,
		deviceType: payload.deviceType,
		node_token: payload.node_token,
	}
}

const setDeviceType = (state: AppState, action): AppState => {
	const payload = action.payload
	return {
		...state,
		deviceType: payload.deviceType,
	}
}

const updateMessage = (state: AppState, action): AppState => {
	const { text, isError, milliseconds } = action.payload
	return { ...state, message: text ? { text, isError, milliseconds } : null }
}

const switchModal = (state: AppState, action) => {
	const payload = action.payload
	return {
		...state,
		modal: payload.name ? payload : null,
	}
}

const updateUser = (state: AppState, action) => {
	const payload = action.payload
	return {
		...state,
		user: payload.data,
		token: payload.token,
		node_token: payload.node_token,
		warehouse: payload.data.warehouse,
	}
}

const loadTransferList = (state: AppState, action) => {
	const payload = action.payload
	return {
		...state,
		transferList: payload.transferlist,
	}
}
const loadAuditList = (state: AppState, action) => {
	const payload = action.payload
	return {
		...state,
		AuditList: payload.auditlist,
	}
}

const loadAuditItem = (state: AppState, action) => {
	const payload = action.payload
	return {
		...state,
		currentAuditItem: payload.currentAuditItem,
	}
}
const loadExtraItem = (state: AppState, action) => {
	const payload = action.payload
	return {
		...state,
		extraItem: payload.extraItem,
	}
}

const loadDistinctItems = (state: AppState, action) => {
	const payload = action.payload
	return {
		...state,
		distictAduitProducts: payload.distictAduitProducts,
	}
}
const logout = () => {
	return {
		...initialState,
	}
}

const switchWarehouse = (state: AppState, action) => {
	const payload = action.payload
	return {
		...state,
		activeWarehouseIndex: payload.index,
	}
}

export default (state: AppState, action): AppState => {
	const map: { [key: string]: (state: AppState, action) => AppState } = {
		[AppActionTypes.INITIALIZE]: initialize,
		[AppActionTypes.DISPLAY_MESSAGE]: updateMessage,
		[AppActionTypes.MODAL_SWITCH]: switchModal,
		[AppActionTypes.LOGIN_SUCCESS]: updateUser,
		[AppActionTypes.LOAD_TRANSFERLIST]: loadTransferList,
		[AppActionTypes.LOAD_AUDIT_LIST]: loadAuditList,
		[AppActionTypes.ADD_AUDIT_ITEM]: loadAuditItem,
		[AppActionTypes.ADD_DISTINCT_AUDIT_ITEM_PRODUCTS]: loadDistinctItems,
		[AppActionTypes.LOAD_EXTRA_ITEM]: loadExtraItem,
		[AppActionTypes.LOGOUT_SUCCESS]: logout,
		[AppActionTypes.WAREHOUSE_SWITCH]: switchWarehouse,
		[AppActionTypes.SET_DEVICE_TYPE]: setDeviceType,
	}
	const reducer = map[action.type]
	return reducer ? reducer(state, action) : state || initialState
}
